    /* You can add global styles to this file, and also import other style files */
    
    html,
    body {
        height: 100%;
        background: linear-gradient(to bottom right, #58bcc8, #3f51b5);
        z-index: -6;
        position: relative;
    }
    
    body {
        margin: 0;
        padding: 0;
        font-family: Roboto, "Helvetica Neue", sans-serif;
        /* background-color: #f0f0f0 !important; */
    }
    
    .w-530 {
        max-width: 530px;
    }
    
    .rotation-block {
        display: none;
    }
    
    .shadow-effect {
        position: relative;
    }
    
    .shadow-effect:before,
    .shadow-effect:after {
        z-index: -1;
        position: absolute;
        content: "";
        bottom: 15px;
        left: 10px;
        width: 50%;
        top: 80%;
        max-width: 300px;
        -webkit-box-shadow: 0 15px 10px #000000;
        -moz-box-shadow: 0 15px 10px #000000;
        box-shadow: 0 15px 10px #000000;
        -webkit-transform: rotate(-3deg);
        -moz-transform: rotate(-3deg);
        -o-transform: rotate(-3deg);
        -ms-transform: rotate(-3deg);
        transform: rotate(-3deg);
    }
    
    .shadow-effect:after {
        -webkit-transform: rotate(3deg);
        -moz-transform: rotate(3deg);
        -o-transform: rotate(3deg);
        -ms-transform: rotate(3deg);
        transform: rotate(3deg);
        right: 10px;
        left: auto;
    }
    
    .card-width {
        width: 65%;
    }
    
    @media only screen and (max-width: 600px) {
        .margin {
            margin: 0 15px;
        }
        .card-width {
            width: 100%;
        }
    }
    
    @media screen and (min-width: 320px) and (max-width: 812px) and (orientation: landscape) {
        /* .rotation-block {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1025;
    display: block;
    overflow: hidden;
    margin: 0 auto;
  }
  .MainBlock {
    display: none;
  } */
        .appblock {
            display: none;
        }
    }
    
    .pointer {
        cursor: pointer;
    }
    
    .center-div {
        transform: translate(50%, 50%);
    }
    
    main {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    main .right-sec {
        padding-right: 70px;
    }
    
    .logo {
        max-width: 180px;
    }
    
    main .left-sec {
        padding-left: 100px;
    }
    /* .left-sec button {
  padding: 15px 45px;
  text-align: center;
  font-size: 14px;
  color: #fff;
  border: none;
  background-image: linear-gradient(to right, #649bff, #0070fa, #649bff);
  border-radius: 10px;
} */
    
    .header {
        width: 100%;
        height: 100vh;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        overflow: hidden;
        /* background-image: url(' assets/img/slider-bg-1.svg'); */
    }
    /* Form Field */
    
    .wrap-login100 {
        width: 450px;
        background: #fff;
        position: absolute;
        right: 20%;
        top: 30%;
    }
    
    @media screen and (max-width: 1280px) {
        .wrap-login100 {
            right: 0%;
            top: 30%;
            width: 100%;
        }
    }
    
    @media screen and (max-width: 768px) {
        .wrap-login100 {
            top: 100px;
        }
        .mobile-none {
            display: none;
        }
    }
    /*[ Social item ]*/
    
    .flex-social {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .login100-social-item {
        font-size: 25px;
        color: #fff;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin: 5px;
        -webkit-transition: all 0.4s;
        -o-transition: all 0.4s;
        -moz-transition: all 0.4s;
        transition: all 0.4s;
    }
    
    .login100-social-item:hover {
        color: #fff;
        background-color: #333333;
        text-decoration: none;
    }
    
    .bg1 {
        background-color: #3b5998;
    }
    
    .bg2 {
        background-color: #0177d7;
    }
    
    .bg3 {
        background-color: #ea4335;
    }
    
    .bg4 {
        background-color: #00aaf2;
    }
    
    .txt1 {
        font-size: 14px;
        line-height: 1.5;
        color: #666666;
    }
    
    input.mat-input-element {
        height: 17px;
        vertical-align: top;
        font-size: 14px;
    }
    
    @media only screen and (max-width: 568px) {
        .logo {
            -webkit-width: 180px;
            -moz-width: 180px;
            width: 180px;
            -webkit-height: auto;
            -moz-height: auto;
            height: auto;
        }
        .register mat-form-field {
            margin-bottom: 12px;
        }
    }
    /* Eye icon color */
    
    .eye-color {
        color: #cccccc;
    }
    
    .hide-icon {
        font-size: 16px !important;
        cursor: pointer;
    }